import { defineStore } from 'pinia'
import { firebaseTools, firebaseAuth } from '@/services/firebase'
import { User, Unsubscribe } from 'firebase/auth'
import { getInstances } from '@/services/queries'
import { client } from '@/services/hasura'
import * as semver from 'semver'
import methods from '@/utils/methods'
import {signInWithCustomToken} from 'firebase/auth'
import { state as localStorage } from '@/services/localStorage'
import axios from 'axios'

interface ICustomer {
  id: string
  name: string
}

export interface IUser {
  name: string
  id: string
  private: {
    email: string,
    phone: string
  }
  role: string
  admins: { instance: {name: string, id:string, customer: ICustomer} }[]
  administrator: { instance: {name: string, id:string, customer: ICustomer}, readOnly: Boolean }[]
  user_routes: { instance: {attributes: Record<string, string> }, routeID: string }[]
  customClaims?: Record<string,unknown>,
}

let baseURL = ''
if (window.location.hostname === 'localhost') {
  baseURL = 'http://localhost:5001/buseticas-7f9fd/us-central1/'
} else {
  baseURL = import.meta.env.DEV
  ? `https://${import.meta.env.VITE_TAIL}/buseticas-7f9fd/us-central1/`
  : 'https://us-central1-buseticas-7f9fd.cloudfunctions.net/'
}

export const useAuthStore = defineStore({
  // name of the store
  // it is used in devtools and allows restoring state
  id: 'auth',
  // a function that returns a fresh state
  state: () => ({
    jwtRefresh: null as string | null,
    instanceID: '',
    user: null as User | null,
    account: null as IUser | null,
    jwt: '',
    tokenObserver: null as Unsubscribe | null,
    schema: null as any[] | null,
    awatingSMS: false,
    phoneCredential: null as any | null,
    parcels: null,
    lastPushTimestamp: null as number | null,
    tokenListener: null as Boolean | null,
    fcmToken: null as string | null,
    parcelsSetted: false,
    favoriteRoutes: [],
    toolbarHidden: false,
    deviceID: null as string | null,
    maintenanceModeIsOn: false
  }),
  // optional getters
  getters: {
    isAuthenticated: (state) => !!state.user,
    isVerified: (state) => !!state.user?.emailVerified,
    instances: (state) => state.account?.administrator?.map(a => {
      return {...a.instance, footer: a.instance.customer.name}
    }) || [],
    userInstances: (state) => {
      if (state.parcels) {
        const instanceObj = {}
        for (const routeParcel of state.parcels) {
          instanceObj[routeParcel.instance.id] = {
            ...routeParcel.instance
          }
        }
        return Object.keys(instanceObj).map(instanceID => instanceObj[instanceID])
      } 
      return []
    },
    routes: (state) => {
      if (!state.parcels) return
      const allRoutes = state.parcels.map(parcel => parcel.routes).flat()
      const routeObj = {}
      for (const route of allRoutes) {
        route.parcelIDs = [...(routeObj[route.id]?.parcelIDs || []), route.parcelID]
        route.stops = route?.stops || []
        delete route.parcelID
        delete route.instance.routes
        routeObj[route.id] = route
      }
      // console.info(JSON.stringify(routeObj, null, 2))
      return Object.keys(routeObj).map(routeID => routeObj[routeID]).sort((a, b) => {
        let nameA = a.name.toUpperCase(); // ignore upper and lowercase
        let nameB = b.name.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        // names must be equal
        return 0;
      })
    },
    stops: (state) => {
      if (!state.parcels) return
      const allStops = state.parcels.map(parcel => parcel.stops).flat()
      const stopObj = {}
      for (const stop of allStops) {
        stopObj[stop.place || stop.address] = stop
      }
      return Object.keys(stopObj).map(stopPlace => stopObj[stopPlace || stop.address])
    },
    instance (state) {
      return state.instanceID ? this.instances.find(instanceToFind => instanceToFind.id === state.instanceID) : {}
    },
    parcelTags (state) {
      return this.instance?.parcel_tags?.map((tag, index) => {
        return {
          name: tag.name,
          id: tag.name,
          contractTemplate: tag.contractTemplate,
          color: methods.brandColors[index % (methods.brandColors.length -1)]
        }
      }) || []
    },
    routeTags (state) {
      const tags = this.instance?.route_tags?.map((tag, index) => {
        return {
          name: tag.name,
          id: tag.name,
          color: methods.brandColors[(index + 5) % (methods.brandColors.length -1)] + 'BB'
        }
      }) || []
      const fixedTags = this.instance?.config?.fixedTags || []
      for (const fixedTag of fixedTags) {
        if (tags.findIndex(tag => tag.name === fixedTag.name) === -1) {
          tags.push({
            name: fixedTag.name,
            id: fixedTag.name,
            color: methods.brandColors[(tags.length + 5) % (methods.brandColors.length -1)]
          })
        }
      }
      return tags
    },
    roles: (state) => !!state.account ? state.account.customClaims['x-hasura-allowed-roles'] : [],
    isStaff: (state) => window.apk === 'driver' || state.roles.includes('staff'),
    isOldAPKParent: (state) => typeof window.os !== 'undefined' && semver.lte(window.apkVersion, '2.2.0'),
    isAdmin: (state) => !!state.account && (state.roles.includes('instanceAdmin') || state.roles.includes('support')),
    isSupport: (state) => ['support'].includes(state.account.role),
    isReadOnly: (state) => !!state.account && state.account.administrator?.find(admin => admin.instance.id === state.instanceID)?.readOnly === true,
  },
  // optional actions
  actions: {
    setToolbarHidden(toolbarHidden: boolean) {
      this.toolbarHidden = toolbarHidden
    },
    setLastPushTimestamp(timestamp: number) {
      this.lastPushTimestamp = timestamp
    },
    async setAccount(account: IUser, customClaims: Record<string,string>) {
      if (typeof customClaims !== 'undefined') {
        this.account = {
          ...this.account,
          ...account,
          email: account.private?.email,
          phone: account.private?.phone,
          ...(customClaims && { customClaims }),
          role: customClaims["x-hasura-default-role"],
          ...(account?.admins?.length > 0 ? {administrator: account.admins} : {}),
        }
        switch (customClaims["x-hasura-default-role"]) {
          case 'support':
            if (this.account.administrator?.length) break
            const instancesQuery = await client.query(getInstances).toPromise()
            if (instancesQuery.data) {
              this.account.administrator = instancesQuery.data.instances.map((instance:any) => ({ instance }))
            }
            // if (!this.instanceID) {
            //   this.setInstanceID('2628d30f-1316-404c-9d7c-38a93c648540')
            // }
            break
          // case 'instanceAdmin':
          //   if (!this.instanceID) {
          //     this.setInstanceID(this.account.administrator[0].instance.id)
          //   }
          //   break
        }
        if (this.isStaff) {
          if (typeof BackgroundGeolocation === 'undefined') return
          const deviceInfo = await BackgroundGeolocation.getDeviceInfo()
          this.deviceID = await methods.getDeviceID({
            userID: this.account?.id,
            deviceInfo,
            deviceID: localStorage.value.deviceID,
          })
          localStorage.value.deviceID = this.deviceID || ''
          localStorage.value.osVersion = deviceInfo.version
          localStorage.value.pluginsVersion = window.apkVersion
          // if (localStorage.value.osVersion !== deviceInfo.version || localStorage.value.pluginsVersion !== window.apkVersion) {
          //   this.deviceID = await methods.getDeviceID({
          //     userID: this.account?.id,
          //     deviceInfo,
          //     deviceID: localStorage.value.deviceID,
          //   })
          //   localStorage.value.deviceID = this.deviceID || ''
          //   localStorage.value.osVersion = deviceInfo.version
          //   localStorage.value.pluginsVersion = window.apkVersion
          // } else {
          //   this.deviceID = localStorage.value.deviceID
          // }
        }
      }
    },
    setFavoriteRoutes (routes) {
      if (routes) {
        this.favoriteRoutes = routes
      }
    },
    setParcelData(parcels) {
      this.parcels = parcels?.map(routeParcel => {
        return {
          ...routeParcel.parcel,
          status: routeParcel.status,
          // avatar: routeParcel.parcel.avatar + `?cache=${Date.now()}`,
          routes: [
            ...routeParcel.parcel.stops.map(stop => {
              return [
                ...stop.routes.map(stopRoute => { // stop routes
                  // console.info(routeParcel.parcel.id)
                  return {
                    id: stopRoute.route.id,
                    stopID: stopRoute.stopID,
                    parcelID: routeParcel.parcel.id,
                    cancelations: stopRoute.cancelations,
                    instanceID: stopRoute.instanceID,
                    instance: routeParcel.parcel.instance,
                    routeStopID: stopRoute.id,
                    name: stopRoute.route.name,
                    plate: stopRoute.route.vehicle?.name || '',
                    staff: stopRoute.route.staff,
                  }
                }).flat()
              ].flat()
            }).flat(),
            ...routeParcel.parcel.instance.routes.map(route => { // public routes
              return {
                id: route.id,
                stopID: null,
                parcelID: routeParcel.parcel.id,
                cancelations: [],
                public: true,
                instanceID: routeParcel.parcel.instanceID,
                instance: routeParcel.parcel.instance,
                routeStopID: null,
                name: route.name,
                stops: route.stops,
                plate: route.vehicle?.name || '',
                capacity: route.vehicle?.capacity || 0,
                staff: route.staff,
              }
            })
        ].sort((a, b) => new Date(b.parcelStateTime).getTime() - new Date(a.parcelStateTime).getTime())
        }
      }) || []
      this.parcelsSetted = true
    },
    setTokenObserver(observer: Unsubscribe) {
      this.tokenObserver = observer
    },
    setAwaitingSMS(awaitingSMS: boolean) {
      this.awatingSMS = awaitingSMS
    },
    async setInstanceID(instanceID: string) {
      const actualInstance = this.instanceID
      if (this.account && typeof instanceID == 'string') {
        if (typeof instanceID == 'string') {
          this.instanceID = instanceID
        } 
        if (this.isAdmin && actualInstance !== instanceID) {
          const keyResponse = await axios.post(`${baseURL}generateTypesenseApiKey`, {
            instanceID: instanceID || this.account?.admins[0].instance.id
          })
          if (keyResponse.data.apiKey) {
            localStorage.value.searchKey = keyResponse.data.apiKey
          }
        }
      }
    },
    async signInWithPhone(provider: string, loginData: {phone:string}) {
      const result = await firebaseTools.signInWith(provider, loginData)
      if (typeof  result.user !== 'undefined') {
        this.user = result.user
        this.jwt = result.token
      } else {
        this.phoneCredential = result
      }
    },
    setPhoneCredential(credential: any) {
      this.phoneCredential = credential
    },
    async signIn(provider: string, loginData: {email:string, verificationCode:string}) {
      const {user, token} = await firebaseTools.signInWith(provider, loginData)
      if (user && token) {
        this.user = user
        this.jwt = token
      }
    },
    async signInWithCustomToken(customToken: string) {
      try {
        const userCredential = await signInWithCustomToken(firebaseAuth, customToken)
        const user = userCredential.user
        const token = await userCredential.user.getIdToken(false)
        if (user && token) {
          this.user = user
          this.jwt = token
        }
      } catch (error) {
        console.info(error)
        throw error
      }
    },
    async signInWithCredential(credential: any) {
      try {
        const {user, token} = await firebaseTools.signInWithCredential(credential)
        if (user && token) {
          this.user = user
          this.jwt = token
        }
      } catch (error) {
        console.info(error)
        throw error
      }
    },
    async getNotificationsToken() {
      try {
        return await firebaseTools.getNotificationsToken()
      } catch (error) {
        console.error(error)
      }
    } ,
    async setUser(user: User) {
      if (!this.user) {
        this.user = user
        const shouldRefresh = !!localStorage.value.jwtRefresh && new Date(localStorage.value.jwtRefresh).getTime() < Date.now()
        const {token:jwt, jwtRefresh} = await firebaseTools.getCurrentUserJWT(shouldRefresh)
        this.jwt = jwt
        this.jwtRefresh = jwtRefresh
      }
    },
  }
})
