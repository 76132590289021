import { createRouter, createWebHistory, RouteRecordRaw, RouteLocationNormalized } from 'vue-router'
import { useAuthStore } from '@/stores'
import { firebaseTools } from '@/services/firebase'
import { $vfm } from '@isagaco/vuesaga'
import { state } from '@/services/localStorage'
import * as pgArray from 'postgres-array'
import axios from 'axios'

const handleMaintenanceMode = () => {
  const { maintenanceModeIsOn } = useAuthStore()
  if (maintenanceModeIsOn) return '/maintenance/'
}
const loginRequired = async (to: RouteLocationNormalized, from: RouteLocationNormalized) => {
  const { isAuthenticated, isStaff, isOldAPKParent, user } = useAuthStore()
  if (isOldAPKParent) return '/mandatoryUpdate/'
  if (isAuthenticated) {
    // const user = await firebaseTools.getCurrentUser()
    if (!user) {
      return `/login?refURL=${to.fullPath !== '/login' ? to.fullPath : ''}`
    }
    const shouldRefresh = !!state.value.jwtRefresh && new Date(state.value.jwtRefresh).getTime() < Date.now()
    const customClaims = await firebaseTools.getCustomClaims(user, shouldRefresh)
    const actualJWT = await firebaseTools.getCurrentUserJWT()
    state.value.jwt = actualJWT.token
    state.value.jwtRefresh = actualJWT.jwtRefresh
    if (typeof customClaims === 'undefined') return false
    const roles = customClaims['x-hasura-allowed-roles']
    const defaultRole = customClaims['x-hasura-default-role']
    if (isStaff || roles.includes('staff')) {
      if (['/staff/routeGPS/'].includes(to.path)) return true
      if (defaultRole === 'no-roles') {
        const {data:gpsData} = await axios.get(`/gps/+${user?.email?.split('@')[0]}`)
        if (gpsData) {
          return router.push(`/staff/routeGPS/`)
        }
      }
      const excludedPaths = ['/staffRoutes/', '/account/', '/staff/routeGPS/', '/staff/vehicleCheck/', '/staff/route/'];
      if (!excludedPaths.some(prefix => to.path.startsWith(prefix))) return '/staffRoutes/'
      return true
    } else {
      if (roles.includes('instanceAdmin') || roles.includes('support') || roles.includes('user')) {
        if (to.path === '/' && (roles.includes('instanceAdmin') || roles.includes('support'))){
          let instanceAdmins
          if (customClaims.version === '2') {
            instanceAdmins = typeof customClaims['x-hasura-admin-instanceIDs'] === 'string' ? 
            pgArray.parse(customClaims['x-hasura-admin-instanceIDs']) : null
          } else {
            instanceAdmins = customClaims['x-hasura-admin-instances']
          }
          const instanceID = instanceAdmins && instanceAdmins.length ? instanceAdmins[0] : '2628d30f-1316-404c-9d7c-38a93c648540'
          return `/admin/${instanceID}/dashboard/`
        }
        if (to.path === '/' && roles.includes('user')) {
          return '/user/map'
        }
        return true
      } else {
        if (to.path !== '/parcelOTP' && !to.path.includes('/enrollmentForm')) return '/parcelOTP' // TODO: this is forbidden
      }
    }
  } else {
    const queryString = Object.keys(to.query)
        .map((key) => `${key}=${to.query[key]}`)
        .join('&');
    const finalQuery = queryString ? `&${queryString}` : ''
    return `/login?refURL=${to.fullPath !== '/login' ? `${to.path}${finalQuery}` : ''}`
  }
  return true
}

// const frillRedirect = async (to: RouteLocationNormalized, from: RouteLocationNormalized) => {
//   const { isAuthenticated } = useAuthStore()
//   if (isAuthenticated) {
//     if (to.query.frillRedirect) {
//       return `/sso/frill/?redirect=${to.query.redirect}`
//     } else {
//       return '/'
//     }
//   }
//   return true
// }

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    component: () => import('../views/NotFound.vue'),
    beforeEnter: [loginRequired, handleMaintenanceMode],
  },
  {
    path: '/mandatoryUpdate/',
    component: () => import('../views/mandatoryUpdate.vue'),
    beforeEnter: [()=>{
      const { isOldAPKParent } = useAuthStore()
      if (!isOldAPKParent) return '/'
    }, handleMaintenanceMode]
  },
  {
    path: '/maintenance/',
    component: () => import('../views/maintenance.vue'),
    beforeEnter: [()=>{
      const { maintenanceModeIsOn } = useAuthStore()
      if (!maintenanceModeIsOn) return '/'
    }]
  },
  {
    path: '/staff/routeGPS/',
    component: () => import('../views/gps.vue'),
    beforeEnter: [loginRequired, handleMaintenanceMode],
  },
  {
    path: '/parcelOTP',
    component: () => import('../views/parcelOTP.vue'),
    beforeEnter: [loginRequired, handleMaintenanceMode],
  },
  {
    path: '/staffRoutes/',
    component: () => import('../views/staffRoutes.vue'),
    beforeEnter: [loginRequired, handleMaintenanceMode],
  },
  {
    path: '/staff/vehicleCheck/:vehicleID',
    component: () => import('../views/vehicleCheck.vue'),
    beforeEnter: [loginRequired, handleMaintenanceMode],
  },
  {
    path: '/qrReader/',
    component: () => import('../views/qrReader.vue'),
    beforeEnter: [handleMaintenanceMode],
  },
  {
    path: '/sso/Frill',
    component: () => import('../views/ssoFrill.vue'),
    beforeEnter: [loginRequired, handleMaintenanceMode],
  },
  {
    path: '/enrollmentForm/:customerAlias/:contract',
    name: 'Matrículas',
    component: () => import('../views/enrollmentForm.vue'),
    beforeEnter: [loginRequired, handleMaintenanceMode],
  },
  {
    path: '/routeRequestForm/:customerAlias/:contract',
    name: 'Solicitud de servicios',
    component: () => import('../views/routeRequestForm.vue'),
    beforeEnter: [loginRequired, handleMaintenanceMode],
  },
  {
    path: '/enrollment/:customerAlias/:contract',
    name: 'Bienvenida Matrículas',
    component: () => import('../views/enrollment.vue'),
    beforeEnter: [handleMaintenanceMode],
  },
  {
    path: '/routeRequest/:customerAlias/:contract',
    name: 'Bienvenida Solicitud de Servicios',
    component: () => import('../views/routeRequest.vue'),
    beforeEnter: [handleMaintenanceMode],
  },
  {
    path: '/admin/:instanceID/contract/',
    name: 'Contrato',
    component: () => import('../views/contract.vue'),
    beforeEnter: [loginRequired, handleMaintenanceMode],
  },
  {
    path: '/admin/:instanceID/integrations/',
    name: 'Integraciones',
    component: () => import('../views/integrations.vue'),
    beforeEnter: [loginRequired, handleMaintenanceMode],
  },
  {
    path: '/admin/:instanceID/plans/:planID',
    name: 'Planeador',
    component: () => import('../views/planner.vue'),
    beforeEnter: [loginRequired, handleMaintenanceMode],
  },
  {
    path: '/gmap/',
    name: 'google-map',
    component: () => import('../views/gmap.vue'),
    beforeEnter: [handleMaintenanceMode],
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/login.vue'),
    beforeEnter: [handleMaintenanceMode],
  },
  {
    path: '/admin/:instanceID/optimization/',
    name: 'Optimización',
    component: () => import('../views/optimization.vue'),
    beforeEnter: [loginRequired, handleMaintenanceMode],
  },
  {
    path: '/admin/:instanceID/geofences/',
    name: 'Geocercas',
    component: () => import('../views/geofences.vue'),
    beforeEnter: [loginRequired, handleMaintenanceMode],
  },
  {
    path: '/admin/:instanceID/viewer/',
    name: 'Visualizador',
    component: () => import('../views/viewer.vue'),
    beforeEnter: [loginRequired, handleMaintenanceMode],
  },
  {
    path: '/admin/:instanceID/tags/',
    name: 'Etiquetas',
    component: () => import('../views/tags.vue'),
    beforeEnter: [loginRequired, handleMaintenanceMode],
  },
  {
    path: '/admin/:instanceID/temporals/',
    name: 'Novedades',
    component: () => import('../views/temporals.vue'),
    beforeEnter: [loginRequired, handleMaintenanceMode],
  },
  {
    path: '/admin/:instanceID/roles/',
    name: 'Roles',
    component: () => import('../views/roles.vue'),
    beforeEnter: [loginRequired, handleMaintenanceMode],
  },
  {
    path: '/admin/:instanceID/directory/',
    name: 'Directorio',
    component: () => import('../views/directory.vue'),
    beforeEnter: [loginRequired, handleMaintenanceMode],
  },
  {
    path: '/user/directory/',
    name: 'Directorio Usuario',
    component: () => import('../views/directory.vue'),
    beforeEnter: [loginRequired, handleMaintenanceMode],
  },
  {
    path: '/user/payments/',
    name: 'Pagos usuario',
    component: () => import('../views/userPayments.vue'),
    beforeEnter: [loginRequired, handleMaintenanceMode],
  },
  {
    path: '/user/payments/setup/:instanceID',
    name: 'Pagos instancia',
    component: () => import('../views/userPaymentsInstance.vue'),
    beforeEnter: [loginRequired, handleMaintenanceMode],
    meta: { toolbarHiddenByQuery: ['sendEvent', 'chargesReminder'] },
  },
  {
    path: '/charges/:chargeID',
    name: 'Pago',
    component: () => import('../views/getCharge.vue'),
    beforeEnter: [handleMaintenanceMode],
    meta: { toolbarHidden: true },
  },
  {
    path: '/admin/:instanceID/notifications/',
    name: 'Comunicados',
    component: () => import('../views/notifications.vue'),
    beforeEnter: [loginRequired, handleMaintenanceMode],
  },
  {
    path: '/admin/:instanceID/history/',
    name: 'Históricos',
    component: () => import('../views/history.vue'),
    beforeEnter: [loginRequired, handleMaintenanceMode],
  },
  {
    path: '/admin/:instanceID/tower/',
    name: 'Torre de control',
    component: () => import('../views/tower.vue'),
    beforeEnter: [loginRequired, handleMaintenanceMode],
  },
  {
    path: '/admin/:instanceID/data/:itemType',
    name: 'Datos',
    component: () => import('../views/table.vue'),
    beforeEnter: [loginRequired, handleMaintenanceMode],
  },
  {
    path: '/admin/:instanceID/charges/',
    name: 'Charges',
    component: () => import('../views/charges.vue'),
    beforeEnter: [loginRequired, handleMaintenanceMode],
  },
  {
    path: '/admin/:instanceID/data/:itemType/:itemID',
    name: 'Detalles',
    component: () => import('../views/details.vue'),
    meta: { transition: 'slide-left' },
    beforeEnter: [loginRequired, handleMaintenanceMode],
  },
  {
    path: '/admin/:instanceID/vehicles/consolidate',
    name: 'Consolidado',
    component: () => import('../views/vehiclesConsolidate.vue'),
    meta: { transition: 'slide-left' },
    beforeEnter: [loginRequired, handleMaintenanceMode],
  },
  {
    path: '/admin/:instanceID/dashboard',
    name: 'Tablero',
    component: () => import('../views/dashboard.vue'),
    beforeEnter: [loginRequired, handleMaintenanceMode],
  },
  {
    path: '/supportDashboard',
    name: 'Expertos',
    component: () => import('../views/supportDashboard.vue'),
    beforeEnter: [loginRequired, handleMaintenanceMode],
  },
  {
    path: '/admin/:instanceID/cancelationInbox',
    name: 'Buzón Ausencias',
    component: () => import('../views/cancelationInbox.vue'),
    beforeEnter: [handleMaintenanceMode],
  },
  {
    path: '/admin/:instanceID/addressEditor/:parcelID?/:stopID?',
    name: 'Lugar',
    component: () => import('../views/addressEditor.vue'),
    beforeEnter: [loginRequired, handleMaintenanceMode],
  },
  {
    path: '/user/map/',
    name: 'Acudiente',
    component: () => import('../views/user.vue'),
    beforeEnter: [loginRequired, handleMaintenanceMode],
  },
  {
    path: '/user/inbox/',
    name: 'Buzón',
    component: () => import('../views/inbox.vue'),
    beforeEnter: [handleMaintenanceMode],
  },
  {
    path: '/account/',
    name: 'Cuenta',
    component: () => import('../views/account.vue'),
    beforeEnter: [loginRequired, handleMaintenanceMode],
    meta: { statusbar: 'light' },
  },
  {
    path: '/staff/route/:instanceID/:routeID/:routeType/',
    name: 'Recorrido',
    component: () => import('../views/staffStream.vue'),
    beforeEnter: [loginRequired, handleMaintenanceMode],
  },
  {
    path: '/user/news/',
    name: 'Noticias',
    component: () => import('../views/news.vue'),
  },
  { 
    path: '/:pathMatch(.*)*',
    name: 'NotFound', 
    component: () => import('../views/NotFound.vue'),
    beforeEnter: [handleMaintenanceMode],
  },
]

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes
})

router.beforeEach(async (to, from) => {
  // console.info(to, from)
  const authStore = useAuthStore()
  if (to.meta?.toolbarHidden && typeof window.os === 'undefined') {
    authStore.setToolbarHidden(true)
  }
  if (typeof StatusBar !== 'undefined') {
    if (to.meta?.statusbar === 'light') {
      StatusBar.styleLightContent()
    } else {
      StatusBar.styleDefault()
    }
  }
  if ($vfm.openedModals.length > 0) {
    $vfm.hideAll()
    return false
  }
  // const hash = from.hash || to.redirectedFrom?.hash
  // if (!to.hash && hash !== undefined){
  //   return to.fullPath + hash
  // }
})

router.afterEach((to, from) => {
  const toDepth = to.path.split('/').length
  const fromDepth = from.path.split('/').length
  to.meta.transitionName = toDepth < fromDepth ? 'slide-right' : 'slide-left'
})

router.onError(err => {console.error(err)})

export default router
